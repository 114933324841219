import { IErrorPayload } from "@/fe-core/meta/interfaces/root";
import {
  IAuthenticateRequestPayload,
  IAnonymousSessionRequestPayload,
  ILoginRequestPayload,
  ISessionDetailsViewModel,
  ISessionViewModel,
  IAnonymousSessionViewModel, IPrepareLoginRequestPayload,
} from "@/fe-core/meta/interfaces/session";
import {
  AuthenticateFailureAction,
  AuthenticateRequestAction,
  AuthenticateSuccessAction,
  AnonymousSessionRequestAction,
  AnonymousSessionSuccessAction,
  AnonymousSessionFailureAction,
  LoginSuccessAction,
  LoginFailureAction,
  LoginRequestAction,
  LogoutFailureAction,
  LogoutRequestAction,
  LogoutSuccessAction,
  sessionActionTypes,
  LoginSuccessfulWrapper,
  GetSessionInfoRequestAction,
  GetSessionInfoSuccessAction,
  GetSessionInfoFailureAction,
  ImpersonateAction,
  ClearSessionStateAction,
  CloseSessionExpiredModalAction,
  ClearLoginStateAction, PrepareLoginSuccessAction, PrepareLoginFailureAction, PrepareLoginRequestAction,
  UpdateIsFirstLoginAction,
} from "@/fe-core/meta/types/session";

export const prepareLoginRequest = (
    payload: IPrepareLoginRequestPayload
): PrepareLoginRequestAction => ({
  type: sessionActionTypes.PREPARE_LOGIN_REQUEST,
  payload,
});

export const prepareLoginSuccess = (payload): PrepareLoginSuccessAction => ({
  type: sessionActionTypes.PREPARE_LOGIN_SUCCESS,
  payload,
});

export const prepareLoginFailure = (payload: IErrorPayload): PrepareLoginFailureAction => ({
  type: sessionActionTypes.PREPARE_LOGIN_FAILURE,
  payload,
});

export const clearPrepareLoginState = (): ClearLoginStateAction => ({
  type: sessionActionTypes.CLEAR_PREPARE_LOGIN_STATE,
});

export const loginRequest = (
  payload: ILoginRequestPayload
): LoginRequestAction => ({
  type: sessionActionTypes.LOGIN_REQUEST,
  payload,
});

export const loginSuccess = (): LoginSuccessAction => ({
  type: sessionActionTypes.LOGIN_SUCCESS,
});

export const loginFailure = (payload: IErrorPayload): LoginFailureAction => ({
  type: sessionActionTypes.LOGIN_FAILURE,
  payload,
});

export const loginSuccessfulWrapper = (): LoginSuccessfulWrapper => ({
  type: sessionActionTypes.LOGIN_SUCCESSFUL_WRAPPER,
});

export const updateIsFirstLogin = (payload: boolean): UpdateIsFirstLoginAction => ({
  type: sessionActionTypes.UPDATE_IS_FIRST_LOGIN,
  payload,
});

export const authenticateRequest = (
  payload: IAuthenticateRequestPayload
): AuthenticateRequestAction => ({
  type: sessionActionTypes.AUTHENTICATE_REQUEST,
  payload,
});

export const closeSessionExpiredModal = (): CloseSessionExpiredModalAction => ({
  type: sessionActionTypes.CLOSE_SESSION_EXPIRED_MODAL,
});

export const authenticateSuccess = (
  payload: ISessionViewModel
): AuthenticateSuccessAction => ({
  type: sessionActionTypes.AUTHENTICATE_SUCCESS,
  payload,
});

export const authenticateFailure = (
  payload: IErrorPayload
): AuthenticateFailureAction => ({
  type: sessionActionTypes.AUTHENTICATE_FAILURE,
  payload,
});

export const anonymousSessionRequest = (
  payload: IAnonymousSessionRequestPayload
): AnonymousSessionRequestAction => ({
  type: sessionActionTypes.ANONYMOUS_SESSION_REQUEST,
  payload,
});

export const anonymousSessionSuccess = (
  payload: IAnonymousSessionViewModel
): AnonymousSessionSuccessAction => ({
  type: sessionActionTypes.ANONYMOUS_SESSION_SUCCESS,
  payload,
});

export const anonymousSessionFailure = (
  payload: IErrorPayload
): AnonymousSessionFailureAction => ({
  type: sessionActionTypes.ANONYMOUS_SESSION_FAILURE,
  payload,
});

export const logoutRequest = (): LogoutRequestAction => ({
  type: sessionActionTypes.LOGOUT_REQUEST,
});

export const logoutSuccess = (): LogoutSuccessAction => ({
  type: sessionActionTypes.LOGOUT_SUCCESS,
});

export const logoutFailure = (payload: IErrorPayload): LogoutFailureAction => ({
  type: sessionActionTypes.LOGOUT_FAILURE,
  payload,
});

export const getSessionInfoRequest = (): GetSessionInfoRequestAction => ({
  type: sessionActionTypes.SESSION_INFO_REQUEST,
});

export const getSessionInfoSuccess = (
  payload: ISessionDetailsViewModel
): GetSessionInfoSuccessAction => ({
  type: sessionActionTypes.SESSION_INFO_SUCCESS,
  payload,
});

export const getSessionInfoFailure = (
  payload: IErrorPayload
): GetSessionInfoFailureAction => ({
  type: sessionActionTypes.SESSION_INFO_FAILURE,
  payload,
});

export const impersonateRequest = (
  payload: string
): ImpersonateAction => ({
  type: sessionActionTypes.IMPERSONATE,
  payload,
})
export const clearSessionState = (): ClearSessionStateAction => ({
  type: sessionActionTypes.CLEAR_SESSION_STATE,
});

export const clearLoginState = (): ClearLoginStateAction => ({
  type: sessionActionTypes.CLEAR_LOGIN_STATE,
});