import { useDispatch, useSelector } from "react-redux";

import { trackingEvents } from "@/config/tracking";
import { useRouter } from "next/navigation";
import TagManager from "react-gtm-module";
import { useFormatter, useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";
import Index from "./SVGIcon";
import useTracking from "./Hooks/useTracking";
import { userBalanceDataSelector } from "../fe-core-omega/_redux/selectors/userSelectors";
import { balanceData } from "@/config/balance";

interface BalanceProps {
  showActions?: boolean;
}

const Balance: React.FC<BalanceProps> = ({ showActions = false }) => {
  const dispatch = useDispatch();
  const format = useFormatter();
  const t = useTranslations(I18N_NAMESPACE.BALANCE);
  const { totalCurrency, ...balanceDataValues } =
    useSelector(userBalanceDataSelector) || {};
  const { click } = useTracking();

  const router = useRouter();

  const getBalanceValue = (key: string) => {
    //@ts-ignore
    if (balanceDataValues?.[key] !== undefined) {
      //@ts-ignore
      return format.number(balanceDataValues?.[key] || 0, {
        style: "currency",
        currency: totalCurrency,
        roundingMode: "floor",
        maximumFractionDigits: 0,
      });
    }

    return (
      <h5 className="text-lg font-semibold opacity-50 text-white/50 animate-pulse">
        --
      </h5>
    );
  };

  const handleDepositClick = () => {
    router.push(`/profile/transactions/depositmoney`);
    TagManager.dataLayer({
      dataLayer: {
        event: trackingEvents.CLICK_DEPOSIT_CASHIER,
      },
    });
  };

  const handleWithdrawClick = () => {
    router.push(`/profile/transactions/withdrawmoney`);
  };

  return (
    <>
      <div className={!showActions ? "" : "container"}>
        <h3
          className={`mb-4 font-bold  text-tertiary dark:text-tertiary-50 ${
            !showActions ? "text-xl" : "text-2.5xl"
          }`}
        >
          {t("balance")}
        </h3>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-2">
          {balanceData &&
            balanceData
              .filter(
                (item) =>
                  item.translationKey === "real-money" ||
                  item.translationKey === "reward"
              )
              .map(
                (item) =>
                  (showActions || !item.hideInHeader) && (
                    <div className="flex flex-col items-center justify-center gap-3 p-4 pb-6 text-center bg-white dark:bg-tertiary-600 rounded text-tertiary dark:text-white">
                      <Index
                        icon={item.icon}
                        className="w-6 h-6 text-primary-500"
                      />
                      <div>
                        <p className="text-sm font-normal">
                          {t(item.translationKey)}
                        </p>
                        <h5 className="text-xl font-bold">
                          {getBalanceValue(item.key)}
                        </h5>
                      </div>
                    </div>
                  )
              )}
        </div>

        {showActions && (
          <div className="flex justify-center gap-4 mt-5">
            <button
              className="btn btn--primary "
              onClick={() => {
                handleDepositClick();
                click("Deposit", {
                  buttonLabel: t("deposit-cta-btn"),
                  component: "Balance",
                });
              }}
            >
              {t("deposit-cta-btn")}
            </button>
            <button
              className="btn btn--white"
              onClick={() => {
                handleWithdrawClick();
                click("withdrawal", {
                  buttonLabel: t("withdrawal-cta-btn"),
                  component: "Balance",
                });
              }}
            >
              {t("withdrawal-cta-btn")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Balance;
