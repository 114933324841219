import { FC } from "react";
import Link from "next/link";
import Image from "next/image";
import classNames from "classnames";

type Props = {
  name: string;
  color?: string;
  size?: string;
  onClick?: () => void;
  href?: string;
  className?: string;
  iconLink?: string | null | undefined;
  isActive?: string;
};

const Icon: FC<Props> = ({
  name,
  color,
  size,
  onClick,
  href,
  className,
  iconLink,
  isActive = false,
}) => {
  const isPointer = !!onClick || !!href ? "pointer" : "";

  const getIconComponent = () => (
    <>
      {iconLink ? (
        <div className={classNames("relative w-4 h-4", className)}>
          <Image
            src={iconLink}
            objectFit="cover"
            fill
            alt={name}
            className={`w-full h-full dark:invert ${
              isActive ? "dark:opacity-1" : "dark:opacity-70"
            }`}
            loading="lazy"
          />
        </div>
      ) : (
        <div
          className={`icon ${name} ${color ? color : "white"} ${
            size ? size : "small"
          } ${isPointer} ${className ? className : ""}`}
          onClick={onClick}
        />
      )}
    </>
  );

  return href ? (
    <Link href={href} passHref legacyBehavior>
      {getIconComponent()}
    </Link>
  ) : (
    getIconComponent()
  );
};

export default Icon;
