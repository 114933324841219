"use client";

import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useMemo
} from "react";
import { SCREEN_LEVELS } from "@/config/general";

type screenContextType = {
  isMobile: boolean | null;
  isTablet: boolean | null;
  isDesktop: boolean | null;
  screenSizeLevel: number | null;
};

const screenContextDefaultValues: screenContextType = {
  isMobile: null,
  isTablet: null,
  isDesktop: null,
  screenSizeLevel: null,
};

const ScreenContext = createContext<screenContextType>(
    screenContextDefaultValues
);

export function useScreen(): screenContextType {
  return useContext(ScreenContext);
}

type Props = {
  children: ReactNode;
};

export function ScreenProvider({ children }: Props): JSX.Element {
  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [isTablet, setIsTablet] = useState<boolean | null>(null);
  const [isDesktop, setIsDesktop] = useState<boolean | null>(null);
  const [screenSizeLevel, setScreenSizeLevel] = useState<number | null>(null);

  useEffect(() => {
    window.addEventListener("resize", reportWindowSize);

    reportWindowSize();

    return () => {
      window.removeEventListener("resize", reportWindowSize);
    };
  }, []);

  const value = {
    isMobile,
    isTablet,
    isDesktop,
    screenSizeLevel,
  };

  function reportWindowSize() {
    const windowWidth = window.innerWidth;
    const isMobileValue: boolean = windowWidth <= 600;
    const isTabletValue: boolean = windowWidth > 600 && windowWidth <= 1024;
    const isDesktopValue: boolean = windowWidth > 1024;


    if(isMobile != isMobileValue) {
      setIsMobile(isMobileValue);
    }
    if(isTablet != isTabletValue) {
      setIsTablet(isTabletValue);
    }
    if(isDesktop != isDesktopValue) {
      setIsDesktop(isDesktopValue);
    }

    let screenSizeLevelValue = SCREEN_LEVELS.MOBILE;

    if (windowWidth > 600 && windowWidth < 1024) {
      screenSizeLevelValue = SCREEN_LEVELS.TABLET;
    } else if (windowWidth > 1024 && windowWidth < 1400) {
      screenSizeLevelValue = SCREEN_LEVELS.DESKTOP;
    } else if (windowWidth > 1400) {
      screenSizeLevelValue = SCREEN_LEVELS.DESKTOP_LARGE;
    }
    if(screenSizeLevel != screenSizeLevelValue) {
      setScreenSizeLevel(screenSizeLevelValue);
    }
  }

  return (
      <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>
  );
}
