import React, { FC } from "react";
import Link from "next/link";

import Icon from "../Icon/Icon";
import { useSelector } from "react-redux";
import { consentRequirementsSelector } from "@/fe-core/_redux/selectors/userSelectors";

const MenuGroupItem: FC<{
  children:
    | [
        React.ReactElement<typeof Icon>,
        JSX.Element | string,
        JSX.Element | string
      ]
    | [React.ReactElement<typeof Icon>, JSX.Element | string]
    | JSX.Element
    | string;
  onClick?: () => void;
  href?: string;
  external?: boolean;
  active?: boolean;
  className?: string;
}> = ({ children, onClick, href, external, active, className }) => {
  const consents = useSelector(consentRequirementsSelector);
  let icon: React.ReactElement<typeof Icon> | undefined,
    content: JSX.Element | string,
    isNew: JSX.Element | string;
  if (Array.isArray(children)) {
    [icon, content, isNew] = children as [
      React.ReactElement<typeof Icon>,
      JSX.Element,
      JSX.Element
    ];
  } else {
    content = children;
  }

  const getIconComponent = () => icon && <>{icon}</>;

  const getMenuGroupItemComponent = () => (
    <Link
      href={href || "#"}
      className={`relative cursor-pointer rounded flex text-left hover:opacity-70 transition-all text-sm gap-3 text-tertiary/50 font-bold py-2.5 px-4 dark:text-white/50 dark:hover:bg-tertiary-500/50 ${
        active ? "!text-tertiary dark:!text-white !bg-tertiary-500/50" : ""
      } ${className ? " " + className : ""}`}
      onClick={onClick}
    >
      {getIconComponent()}
      <span className="">
        {content}
        {isNew ? isNew : <></>}
      </span>
    </Link>
  );

  return href ? (
    href === "/profile/global-settings" && consents && consents?.length <= 0 ? (
      <></>
    ) : external ? (
      <Link
        href={href}
        passHref
        className="menu-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {getMenuGroupItemComponent()}
      </Link>
    ) : (
      <Link href={href} passHref legacyBehavior>
        {getMenuGroupItemComponent()}
      </Link>
    )
  ) : (
    getMenuGroupItemComponent()
  );
};

export default MenuGroupItem;
