import { IErrorPayload, IStatus } from "../interfaces/root";
import {
  ISessionViewModel,
  ILoginRequestPayload,
  IAuthenticateRequestPayload,
  IAnonymousSessionRequestPayload,
  ISessionDetailsViewModel,
  IAnonymousSessionViewModel, IPrepareLoginRequestPayload,
} from "../interfaces/session";
import {
  GetCeSessionFailureAction,
  GetCeSessionRequestAction,
  GetCeSessionSuccessAction,
} from "@/fe-core/meta/types/ceSession";

export enum sessionActionTypes {
  CE_SESSION_SUCCESS = "CE_SESSION_SUCCESS",
  PREPARE_LOGIN_REQUEST = "PREPARE_LOGIN_PREPARE_REQUEST",
  PREPARE_LOGIN_SUCCESS = "PREPARE_LOGIN_SUCCESS",
  PREPARE_LOGIN_FAILURE = "PREPARE_LOGIN_FAILURE",
  CLEAR_PREPARE_LOGIN_STATE = "CLEAR_PREPARE_LOGIN_STATE",
  LOGIN_REQUEST = "LOGIN_REQUEST",

  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGIN_SUCCESSFUL_WRAPPER = "LOGIN_SUCCESSFUL_WRAPPER",
  UPDATE_IS_FIRST_LOGIN = "UPDATE_IS_FIRST_LOGIN",
  AUTHENTICATE_REQUEST = "AUTHENTICATE_REQUEST",
  CLOSE_SESSION_EXPIRED_MODAL = "CLOSE_SESSION_EXPIRED_MODAL",
  AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS",
  AUTHENTICATE_FAILURE = "AUTHENTICATE_FAILURE",

  ANONYMOUS_SESSION_REQUEST = "ANONYMOUS_SESSION_REQUEST",
  ANONYMOUS_SESSION_SUCCESS = "ANONYMOUS_SESSION_SUCCESS",
  ANONYMOUS_SESSION_FAILURE = "ANONYMOUS_SESSION_FAILURE",

  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "LOGOUT_FAILURE",
  SESSION_INFO_REQUEST = "SESSION_INFO_REQUEST",
  SESSION_INFO_SUCCESS = "SESSION_INFO_SUCCESS",
  SESSION_INFO_FAILURE = "SESSION_INFO_FAILURE",

  IMPERSONATE = "IMPERSONATE",
  CLEAR_SESSION_STATE = "CLEAR_SESSION_STATE",
  CLEAR_LOGIN_STATE = "CLEAR_LOGIN_STATE",
}

export type PrepareLoginRequestAction = {
  type: sessionActionTypes.PREPARE_LOGIN_REQUEST;
  payload: IPrepareLoginRequestPayload;
};

export type PrepareLoginSuccessAction = {
  type: sessionActionTypes.PREPARE_LOGIN_SUCCESS;
  payload: any;
};

export type PrepareLoginFailureAction = {
  type: sessionActionTypes.PREPARE_LOGIN_FAILURE;
  payload: IErrorPayload;
};

export type ClearPrepareLoginStateAction = {
  type: sessionActionTypes.CLEAR_PREPARE_LOGIN_STATE;
};

export type LoginRequestAction = {
  type: sessionActionTypes.LOGIN_REQUEST;
  payload: ILoginRequestPayload;
};

export type LoginSuccessAction = {
  type: sessionActionTypes.LOGIN_SUCCESS;
};

export type LoginFailureAction = {
  type: sessionActionTypes.LOGIN_FAILURE;
  payload: IErrorPayload;
};

export type LoginSuccessfulWrapper = {
  type: sessionActionTypes.LOGIN_SUCCESSFUL_WRAPPER;
};

export type UpdateIsFirstLoginAction = {
  type: sessionActionTypes.UPDATE_IS_FIRST_LOGIN;
  payload: boolean
};

export type AuthenticateRequestAction = {
  type: sessionActionTypes.AUTHENTICATE_REQUEST;
  payload: IAuthenticateRequestPayload;
};

export type CloseSessionExpiredModalAction = {
  type: sessionActionTypes.CLOSE_SESSION_EXPIRED_MODAL;
};

export type AuthenticateSuccessAction = {
  type: sessionActionTypes.AUTHENTICATE_SUCCESS;
  payload: ISessionViewModel;
};

export type AuthenticateFailureAction = {
  type: sessionActionTypes.AUTHENTICATE_FAILURE;
  payload: IErrorPayload;
};

export type AnonymousSessionRequestAction = {
  type: sessionActionTypes.ANONYMOUS_SESSION_REQUEST;
  payload: IAnonymousSessionRequestPayload;
};

export type AnonymousSessionSuccessAction = {
  type: sessionActionTypes.ANONYMOUS_SESSION_SUCCESS;
  payload: IAnonymousSessionViewModel;
};

export type AnonymousSessionFailureAction = {
  type: sessionActionTypes.ANONYMOUS_SESSION_FAILURE;
  payload: IErrorPayload;
};

export type LogoutRequestAction = {
  type: sessionActionTypes.LOGOUT_REQUEST;
};

export type LogoutSuccessAction = {
  type: sessionActionTypes.LOGOUT_SUCCESS;
};

export type LogoutFailureAction = {
  type: sessionActionTypes.LOGOUT_FAILURE;
  payload: IErrorPayload;
};

export type GetSessionInfoRequestAction = {
  type: sessionActionTypes.SESSION_INFO_REQUEST;
};

export type GetSessionInfoSuccessAction = {
  type: sessionActionTypes.SESSION_INFO_SUCCESS;
  payload: ISessionDetailsViewModel;
};

export type GetSessionInfoFailureAction = {
  type: sessionActionTypes.SESSION_INFO_FAILURE;
  payload: IErrorPayload;
};

export type ImpersonateAction = {
  type: sessionActionTypes.IMPERSONATE;
  payload: string;
}

export type ClearSessionStateAction = {
  type: sessionActionTypes.CLEAR_SESSION_STATE;
};

export type ClearLoginStateAction = {
  type: sessionActionTypes.CLEAR_PREPARE_LOGIN_STATE;
};

export type SessionActions =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LoginSuccessfulWrapper
  | AuthenticateRequestAction
  | UpdateIsFirstLoginAction
  | CloseSessionExpiredModalAction
  | AuthenticateSuccessAction
  | AuthenticateFailureAction
  | AnonymousSessionRequestAction
  | AnonymousSessionSuccessAction
  | AnonymousSessionFailureAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | GetSessionInfoRequestAction
  | GetSessionInfoSuccessAction
  | GetSessionInfoFailureAction
  | GetCeSessionRequestAction
  | GetCeSessionSuccessAction
  | GetCeSessionFailureAction
  | ImpersonateAction
  | ClearLoginStateAction
  | ClearSessionStateAction;

export type SessionState = {
  data: ISessionViewModel | null;
  isFirstLogin: boolean,
  login: {
    pending: boolean;
    status: IStatus | null;
  };

  prepareLogin: {
    pending: boolean;
    status: IStatus | null;
    data: any;
  };
  authenticate: {
    pending: boolean;
    status: IStatus | null;
    isUserIdentified: boolean | null
  };
  ceSession: {
    pending: boolean;
    status: IStatus | null;
    ceSessionId: string | null;
  };
  logout: {
    pending: boolean;
    status: IStatus | null;
  };
};
