export const balanceData = [
    {
        icon: "wallet",
        translationKey: "real-money",
        key: "total",
        hideInHeader: false
    },
    {
        icon: "reward",
        translationKey: "reward",
        key: "bonus",
        hideInHeader: false
    },
    {
        icon: "wallet",
        translationKey: "withdrawable-escrow-balance",
        key: "withdrawableEscrowBalance",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "total-escrow-balance",
        key: "totalEscrowBalance",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "loyalty-point",
        key: "loyaltyPoint",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "pre-buy-balance",
        key: "preBuyBalance",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "withdraw-restriction-amount",
        key: "withdrawRestrictionAmount",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "total-withdrawable-balance",
        key: "totalWithdrawableBalance",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "sports-book-playable-bonus-balance",
        key: "sportsbookPlayableBonusBalance",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "casino-playable-bonus-balance",
        key: "casinoPlayableBonusBalance",
        hideInHeader: true
    },
    {
        icon: "wallet",
        translationKey: "pending-bonus-balance",
        key: "pendingBonusBalance",
        hideInHeader: true
    },
]