import { RootState } from '@/fe-core/meta/types/root'
import { userSelector } from '.'
import {
  IBonus,
  IDepositEligibleBonusPlan,
  IDepositEligibleBonusPlansState,
  IEligibleOptInBonusPlan,
  IEligibleOptInBonusPlansState,
  IRedeemBonusState,
} from '@/fe-core/meta/interfaces/user'
import { IStatus } from '@/fe-core/meta/interfaces/root'
import { BonusState } from '@/fe-core/meta/types/user';
import { EXPIRED_BONUS_LIST_STATUS } from '@/config/bonus'

export const userBonusSelector = (state: RootState): BonusState => {
  const { bonus } = userSelector(state)

  return bonus
}

// export const userClaimableBonusesSelector = (
//   state: RootState
// ): IClaimableBonusViewModel[] | null => {
//   const { claimableBonuses } = userBonusSelector(state)

//   return claimableBonuses
// }

export const userGetClaimableBonusesSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { getClaimableBonuses } = userBonusSelector(state)

  return getClaimableBonuses
}

export const userBonusDataSelector = (
  state: RootState
): IBonus[] | null => {
  const { data } = userBonusSelector(state)

  return data
}

export const userBonusesActiveSelector = (
  state: RootState
): IBonus[] | null => {
  const data = userBonusDataSelector(state);
  const activeBonuses = data && data.filter(
    bonus => !EXPIRED_BONUS_LIST_STATUS.includes(bonus?.status)
  );
  return activeBonuses || null
}
export const userBonusesHistorySelector = (
  state: RootState
): IBonus[] | null => {
  const data = userBonusDataSelector(state);
  const activeBonuses = data && data.filter(
    bonus => EXPIRED_BONUS_LIST_STATUS.includes(bonus?.status)
  );
  return activeBonuses || null
}
export const userBonusesActivePendingSelector = (
  state: RootState
): boolean => {
  const data = userBonusSelector(state);
  const isLoading = data?.getBonuses?.pending;
  return isLoading;
}

export const userBonusesTotalClaimedAmountSelector = (
  state: RootState
): number => {
  const { bonus } = userSelector(state)
  const totalClaimedAmount = bonus?.totalClaimedAmount || 0
  return totalClaimedAmount;
}


export const userGetBonusesSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { getBonuses } = userBonusSelector(state)

  return getBonuses
}

export const userGetBonusesPendingSelector = (state: RootState): boolean => {
  const { pending } = userGetBonusesSelector(state)

  return pending
}

export const userGetBonusesStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userGetBonusesSelector(state)

  return status
}

export const eligibleOptInPlansSelector = (
  state: RootState
): IEligibleOptInBonusPlansState => {
  const { getEligibleOptInBonusPlans } = userBonusSelector(state);

  return getEligibleOptInBonusPlans;
};

export const optInBonusSelector = (
  state: RootState
): { pending: { [key: string | number]: boolean }; status: IStatus | null } => {
  const { optInBonus } = userBonusSelector(state);

  return optInBonus;
};

export const optInBonusPendingSelector = (
  state: RootState
): { [key: string | number]: boolean } => {
  const { pending } = optInBonusSelector(state);

  return pending;
};

export const optInBonusStatusSelector = (state: RootState): IStatus | null => {
  const { status } = optInBonusSelector(state);

  return status;
};

export const redeemBonusSelector = (
  state: RootState
): IRedeemBonusState => {
  const { redeemBonus } = userBonusSelector(state);

  return redeemBonus;
};

export const redeemBonusPendingSelector = (
  state: RootState
): boolean => {
  const { pending } = redeemBonusSelector(state);

  return pending;
};

export const redeemBonusStatusSelector = (state: RootState): IStatus | null => {
  const { status } = redeemBonusSelector(state);

  return status;
};

export const eligibleOptInPlansPendingSelector = (state: RootState): boolean => {
  const { pending } = eligibleOptInPlansSelector(state);

  return pending;
};

export const eligibleOptInPlansStatusSelector = (state: RootState): IStatus | null => {
  const { status } = eligibleOptInPlansSelector(state);

  return status;
};

export const depositEligibleBonusPlansSelector = (
  state: RootState
): IDepositEligibleBonusPlansState => {
  const { depositEligibleBonusPlans } = userBonusSelector(state);

  return depositEligibleBonusPlans;
};

export const depositEligibleBonusPlansDataSelector = (
  state: RootState
): IDepositEligibleBonusPlan[] | null => {
  const { data } =
    depositEligibleBonusPlansSelector(state);

  return data;
};

export const depositEligiblePlansPendingSelector = (state: RootState): boolean => {
  const { pending } = depositEligibleBonusPlansSelector(state);

  return pending;
};

export const depositEligiblePlansStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = depositEligibleBonusPlansSelector(state);

  return status;
};

export const eligibleOptInPlansDataSelector = (state: RootState): IEligibleOptInBonusPlan[] | null => {
  const { data } = eligibleOptInPlansSelector(state);

  return data;
};
export const eligibleOptInPlansDataPendingSelector = (state: RootState): boolean => {
  const { pending } = eligibleOptInPlansSelector(state);
  return pending;
};

export const userApplyBonusSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { applyBonus } = userBonusSelector(state)

  return applyBonus
}

export const userApplyBonusPendingSelector = (state: RootState): boolean => {
  const { pending } = userApplyBonusSelector(state)

  return pending
}

export const userApplyBonusStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userApplyBonusSelector(state)

  return status
}

export const userCancelBonusSelector = (
  state: RootState
): { pending: boolean; status: IStatus | null } => {
  const { cancelBonus } = userBonusSelector(state)

  return cancelBonus
}

export const userCancelBonusPendingSelector = (state: RootState): boolean => {
  const { pending } = userCancelBonusSelector(state)

  return pending
}

export const userCancelBonusStatusSelector = (
  state: RootState
): IStatus | null => {
  const { status } = userCancelBonusSelector(state)

  return status
}

export const userShowConfirmationModalSelector = (
  state: RootState
): boolean => {
  const { showConfirmationModal } = userBonusSelector(state)

  return showConfirmationModal
}

// export const userConfirmationBonusSelector = (
//   state: RootState
// ): IClaimableBonusViewModel | null => {
//   const { confirmationBonus } = userBonusSelector(state)

//   return confirmationBonus
// }

// export const isPendingUserSingleWagerBonusSelector = (
//   state: RootState
// ): boolean => {
//   const isPendingLoyaltyLevelsContent = loyaltyLevelsPendingSelector(state);
//   const isPendingWageringBonus = userGetBonusesPendingSelector(state);
//   return !!(isPendingLoyaltyLevelsContent || isPendingWageringBonus);
// }

// export const userSingleWagerBonusContentSelector = (
//   state: RootState
// ): { bonus: IBonus | null | undefined, content: ILoyaltyLevelsModel | null | undefined } | null => {
//   const loyaltyLevels = loyaltyLevelsSelector(state);
//   const data = userBonusDataSelector(state);

//   const bonuses = data?.bonuses?.wagerBonuses || null;
//   const wagerBonus = bonuses?.find(x => x.status === "active" && x.type === "wagering");

//   const mainDataContent = wagerBonus && loyaltyLevels?.find(x => Number(x?.attributes?.bonusId) === Number(wagerBonus?.bonusId));

//   const mainData = wagerBonus && mainDataContent ? { bonus: wagerBonus, content: mainDataContent } : null;
//   return mainData || null;
// }