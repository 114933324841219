import { FC, useState } from "react";
import Icon from "../Icon/Icon";
import { useTranslations } from "next-intl";
import { I18N_NAMESPACE } from "@/config/i18n";

const MenuGroup: FC<{
  title?: string;
  limit?: number;
  className?: string;
  children: (boolean | JSX.Element)[] | JSX.Element;
}> = ({ title, limit, className, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const t = useTranslations(I18N_NAMESPACE.PROFILE);

  if (!children) {
    return null;
  }

  const getTitleComponent = () =>
    title && <div className="menu-group-title">{title}</div>;

  const getChildrenComponents = () => {
    if (!Array.isArray(children)) {
      return children;
    }

    return isExpanded || !limit || children.length <= limit
      ? children
      : children.slice(0, 6);
  };

  const handleExpanderClick = () => {
    setIsExpanded(!isExpanded);
  };

  const getExpanderComponent = () => {
    if (!Array.isArray(children) || !limit || children.length <= limit) {
      return null;
    }

    const showModeText = isExpanded ? t("show-less") : t("show-more");

    const showMoreIcon = isExpanded ? "arrow-up" : "arrow-down";

    return (
      <div className="show-more" onClick={handleExpanderClick}>
        <span className="text-xs label">{showModeText}</span>
        <Icon name={showMoreIcon} size="x-small" color="primary" />
      </div>
    );
  };

  return (
    <>
      {getTitleComponent()}
      <div className={`flex flex-col w-full ${className ? className : ""}`}>
        {getChildrenComponents()}
        {getExpanderComponent()}
      </div>
    </>
  );
};

export default MenuGroup;
