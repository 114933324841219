import classNames from "classnames";
import React, { FC } from "react";

type Props = {
  className?: string;
};

const DotNotification: FC<Props> = ({ className }) => {
  return (
    <span
      className={classNames("absolute flex w-2 h-2 -right-4 -top-1", className)}
    >
      <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-gradient-to-r from-primary-200 to-primary-200"></span>
      <span className="relative inline-flex w-2 h-2 rounded-full bg-gradient-to-r from-primary-600 to-primary-600"></span>
    </span>
  );
};

export default DotNotification;
